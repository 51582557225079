import React from 'react'
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

function Home() {
    const navigate= useNavigate();
    const logOut = () => {
        localStorage.clear();
        navigate("/");
    };

    return (
        <div>
            <h1>Welcome to Home Page!</h1>
            <Button variant="danger" onClick={logOut}>
                Log Out
            </Button>
        </div>
    )
}

export default Home
