import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/img/logo_cleanup 1.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [response, setResponse] = useState('');
  const navigate = useNavigate();



  const handleSubmit = async (event) => {
    event.preventDefault();
    /*
    try {
      const result = await axios.post('http://localhost:8080/login', {
        username,
        password
      });
      setResponse(result.data);
      setError('');
      localStorage.setItem("spt", result.data.token);
      navigate('/home');
    } catch (err) {
      const errorMessage = err.response ? err.response.data : err.message;
      setError(errorMessage);
      setResponse('');

      Swal.fire({
        title: "Error",
        text: "Invalid username or password",
        icon: "error",
        confirmButtonColor: "#FF0000",
      });
    }
    */
    Swal.fire({
      title: "Error",
      text: "Invalid username or password",
      icon: "error",
      confirmButtonColor: "#FF0000",
    });
  };


  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center justify-content-center w-100">
                <div className="row justify-content-center w-100">
                  <div className="col-sm-6 col-md-6 col-lg-6 mx-auto">
                    <div className="card mb-0">
                      <div className="card-body">
                        <a href="./index.html" className="text-nowrap logo-img text-center d-block py-3 w-100">
                          <img src={logo} width="129px" alt="Logo" />
                        </a>
                        <form onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <label htmlFor="username" className="form-label">Username</label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              required
                              placeholder="Enter Username or Email Address"
                            />
                          </div>
                          <div className="mb-4">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                              placeholder="Enter Password"
                            />
                          </div>
                          <div className="d-flex align-items-center justify-content-between mb-4">
                            <div className="form-check">
                              <input className="form-check-input primary" type="checkbox" id="flexCheckChecked" />
                              <label className="form-check-label text-dark" htmlFor="flexCheckChecked">
                                Keep me signed in
                              </label>
                            </div>
                          </div>
                          <Button className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2" type="submit">
                            Sign In
                          </Button>
                          {/*<div className="d-flex align-items-center justify-content-center">
                            <a className="text-primary fw-bold ms-2" href="./authentication-register.html">
                              Forgot your password?
                            </a>
                          </div>*/} 
                        </form>
                        <dir className="h-100 d-flex align-items-center justify-content-center">© 2024 SUN PEAK TECH</dir>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Col>
      </Row>
      
    </Container>
  );
};

export default LoginPage;
