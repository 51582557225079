import './App.css';
import * as React from "react";
import LoginPage from './pages/login/login';
import Home from './pages/home/home';
import {createBrowserRouter,RouterProvider,Route,Link,} from "react-router-dom";
import ProtectedRoute from './component/protect';

function App() { 
  const router=createBrowserRouter(
    [
      {
        path: "/",
        element: <LoginPage/>
      },
      {
        path:"/home",
        element: <ProtectedRoute />,
        children: [
          {
            path: "/home",
            element: <Home />,
          }
        ]
      }
    ]
  );
  return (
    <>
      <RouterProvider router= {router}/>
    </>
  );
}
export default App;










